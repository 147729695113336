import NextErrorComponent from 'next/error'
import styled from 'styled-components'
import * as Sentry from '@sentry/nextjs'
import {LayoutCard, PageContainer} from '../components'
import {FaWhatsapp} from 'react-icons/fa'
import {useMediaQuery} from 'react-responsive'
import Image from 'next/image'

const MyError = ({statusCode, hasGetInitialPropsRun, err}) => {
  const isTabletOrDesktop = useMediaQuery({query: '(min-width: 768px)'})

  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err)
    // Flushing is not required in this case as it only happens on the client
  }

  return (
    <PageContainer>
      <LayoutCard hideFooter>
        <ErrorIcon>
          <Image
            src="/icons/error-icon.svg"
            alt="Error icon"
            width={isTabletOrDesktop ? 150 : 113}
            height={isTabletOrDesktop ? 84 : 63}
          />
        </ErrorIcon>
        <h1 className="h2">Very Rarely, Something Breaks.</h1>
        <h2 className="h4">This is one of those times :(</h2>
        <h3 className="h4">
          We will fix this and get your policy issued asap.
        </h3>
        <OptionsHeading>Here Are Your Options</OptionsHeading>
        <Option>
          Go <a href="#">back</a> and try again
        </Option>
        <Option>
          <WhatsappIcon />
          <span>WhatsApp us</span>
        </Option>
        <Option>Call us - 087 152 4999</Option>
        <StatusCode>ERROR CODE {statusCode}</StatusCode>
      </LayoutCard>
    </PageContainer>
  )
}

MyError.getInitialProps = async ({res, err, asPath}) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err,
  })

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    Sentry.captureException(err)

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000)

    return errorInitialProps
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
  )
  await Sentry.flush(2000)

  return errorInitialProps
}

export default MyError

const ErrorIcon = styled.div`
  margin-bottom: 21px;

  @media (min-width: 992px) {
    margin-bottom: 35px;
  }
`

const OptionsHeading = styled.p`
  font-weight: var(--font-bold);
  margin-top: calc(var(--spacing-unit) * 2.5);
`

const Option = styled.p`
  font-weight: var(--font-regular);
  color: var(--purple);
  margin-top: calc(var(--spacing-unit) / 1.5);
`

const WhatsappIcon = styled(FaWhatsapp)`
  position: relative;
  top: 2px;
  left: -2px;
`

const StatusCode = styled.p`
  margin-top: calc(var(--spacing-unit) * 2.5);
  font-weight: var(--font-regular);
  font-size: 12px;
`
